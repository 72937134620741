import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import { boxClassName } from '@archipro-design/aria';
export const Page: StyleRule = ({ theme, noUserScalable = false }) => ({
    width: '100%',
    height: '100%',
    color: theme.siteVariables.colorScheme.default?.foreground,
    background: theme.siteVariables.colorScheme.default?.background,
    touchAction: noUserScalable ? 'pan-x pan-y' : 'auto',
});

export const PageContent: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colorScheme.default?.foreground,
    background: theme.siteVariables.colorScheme.default?.background,

    [theme.screen.laptop]: {
        width: '100%',
        marginBottom: pxToRem(-1),
        paddingBottom: pxToRem(240),
        minHeight: 'unset',
    },
});

export const Container: StyleRule = ({ theme }) => ({
    [theme.screen.laptop]: {
        padding: pxArrayToRem([0, 50]),
    },
    [theme.screen.clamped]: {
        padding: 0,
    },
});

export const MobileContent: StyleRule = () => ({
    '@supports(height: 100dvh)': {
        maxHeight: `calc(100dvh - ${pxToRem(68)})`,
    },
    maxHeight: `calc(100vh - ${pxToRem(68)})`,
    overflow: `auto`,
    paddingBottom: pxToRem(24),
});

export const Sections: StyleRule = ({ theme }) => ({
    gridGap: pxToRem(80),

    [theme.screen.laptop]: {
        gridGap: pxToRem(120),
    },
});

export const Section: StyleRule = ({ theme }) => ({
    [theme.screen.laptop]: {
        gridGap: pxToRem(24),
    },
});

export const SectionTitle: StyleRule = ({ theme }) => ({
    textAlign: `left`,
    margin: pxArrayToRem([0, 18, 20, 18]),
    letterSpacing: theme.siteVariables.letterSpacingMedium,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    ...theme.siteVariables.textStyles.Text.Medium65,

    [theme.screen.laptop]: {
        letterSpacing: pxToRem(0.4),
        lineHeight: 1.75,
        fontSize: pxToRem(20),
        margin: 0,
    },
});

export const TestimonialModalWrapper: StyleRule = () => ({
    padding: pxArrayToRem([0, 18, 32, 18]),
    [`& .${boxClassName}`]: {
        paddingTop: pxToRem(34),
        width: pxToRem(377.5),
    },
});

export const PageEndWrapper: StyleRule = () => ({});
